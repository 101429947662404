import React from 'react'
import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { Link } from 'gatsby'
import { IndexQueryQuery, PostByPathQuery } from '../../../types/graphql-types'
import Meta from 'components/meta/meta'
import Layout from 'components/layout/layout'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import Video from 'components/video'
import BackgroundImage from 'gatsby-background-image'
import OGImage from 'images/doctor-dentist-puts-the-retractor-to-the-patient.jpg'
import Logo from 'images/logo.png'
import { Helmet } from 'react-helmet'
import BtnOutlined from 'components/button/outlined-button'
import TelLinkSimple from 'components/tel-link-simple'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const RootCanalTherapyPage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const pd_cta_background = data.pd_cta_background?.childImageSharp?.fluid
  const service_rootcanal_1 = data.service_rootcanal_1?.childImageSharp?.fluid
  const service_rootcanal_2 = data.service_rootcanal_2?.childImageSharp?.fluid

  return (
    <Layout location={location}>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>Root Canal Therapy - The Dentists At Gateway Crossing</title>
      <meta name="description" content="We offer certain endodontic procedures like root canal therapy to our patients in need. Browse the page below to learn more. It's easy to schedule your" />
      <meta name="robots" content="index, follow, max-snippet:-1, max-video-preview:-1, max-image-preview:large" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content="Root Canal Therapy - The Dentists At Gateway Crossing" />
      <meta property="og:description" content="We offer certain endodontic procedures like root canal therapy to our patients in need. Browse the page below to learn more. It's easy to schedule your" />
      <meta property="og:url" content="https://thedentistsatgc.com/services/root-canal-therapy/" />
      <meta property="og:site_name" content="The Dentists At Gateway Crossing" />
      <meta property="article:publisher" content="https://www.facebook.com/thedentistsatgc/" />
      <meta property="og:updated_time" content="2021-02-05T05:10:25+00:00" />
      <meta property="og:image" content={OGImage} />
      <meta property="og:image:secure_url" content={OGImage} />
      <meta property="og:image:width" content="2000" />
      <meta property="og:image:height" content="1333" />
      <meta property="og:image:alt" content="doctor-dentist-puts-the-retractor-to-the-patient " />
      <meta property="og:image:type" content="image/jpeg" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Root Canal Therapy - The Dentists At Gateway Crossing" />
      <meta name="twitter:description" content="We offer certain endodontic procedures like root canal therapy to our patients in need. Browse the page below to learn more. It's easy to schedule your" />
      <meta name="twitter:image" content={OGImage} />
      <meta name="google-site-verification" content="khYQGQ-T8YWkXa0QyKr6e2kNlFB3l0ZiFRA89VzVbaM" />
      <meta name="google-site-verification" content="Oll6PjpMRzNx1KbgKilxS6xPddvSbF2lDOa2rd7VkxA" />
      <meta name="msapplication-TileImage" content={Logo} />
      <script async src='https://tag.simpli.fi/sifitag/195cd770-bf42-013a-5143-0cc47a8ffaac'></script>
    </Helmet>
      <BackgroundImage
        Tag="section"
        className="hero-section-bg"
        fluid={hero_background}
        backgroundColor={`#e9f4f8`}
      >
        <div className ="col-lg-12" id ="color-overlay">
        </div>
        <div id="hero-section">
          <Container>
            <div className="col-lg-12 text-center">
              <p className="hero-text">
              ENDODONTIC THERAPY MCCORDSVILLE
              </p>
              <h1 className="text-light">
              Root Canal Therapy
              </h1>
            </div>
            <div className="col-lg-12 text-center">
              <p className="hero-text text-light">We offer certain endodontic procedures like root canal therapy to our patients in need. Browse the page below to learn more. It’s easy to schedule your appointment with us. </p>
              <p className="hero-text text-light">Just click “schedule now” as soon as you’re ready.</p>
            </div>
            <div className="col-lg-12 text-center">
              <a href="#pd-section-3"><button className="btn-rounded"> Learn More </button></a>
              <a href="https://flexbook.me/tdagc/website" target="_blank"><button className="btn-rounded"> Schedule Now </button></a>
            </div>
          </Container>
          </div>
      </BackgroundImage>
      <section id ="pd-section-1" className="page-section text-center">
          <Container>
            <div className="col-lg-12">
              <p className ="section-1-text">ROOT CANAL TREATMENT</p>
              <h2 className ="section-2-header">The Root Canal, Not As Scary As You Think</h2>
              <p>You have surely heard of a root canal but you might not understand what they are and how they are done. Most of the time root canals are associated with lots of pain, but this is not the norm. If you’ve been putting of a root canal you might be surprised at the minimal amount of pain involved. Dr. Vogt is a very gentle dentist and makes sure that his patient are comfortable and well informed through every endodontic procedure like a root canal.</p>
            </div>
          </Container>
          <Container>
            <div className="col-lg-4">
              <div className="columns">
                <i className="fa fa-bolt" aria-hidden="true"></i>
                <h3 className ="section-1-header">Keeps Your Natural Tooth</h3>
                <p>Extractions are not always the answer. With a root canal we can save your natural tooth and if you take care of it, it will last a lifetime.</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="columns">
                <i className="fa fa-diamond" aria-hidden="true"></i>
                <h3 className ="section-1-header">Virtually Pain Free</h3>
                <p>Patients that undergo a root canal will experience much less pain than a patient that decided to go with an extraction instead.</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="columns">
                <i className="fa fa-bookmark-o" aria-hidden="true"></i>
                <h3 className ="section-1-header">Save Money</h3>
                <p>Endodontic treatments are usually more cost effective than extractions. They require less follow up visits and no dentures, bridges, or implants.</p>
              </div>
            </div>
          </Container>
          <Container>
            <div className="col-lg-4">
              <div className="columns">
                <i className="fa fa-bolt" aria-hidden="true"></i>
                <h3 className ="section-1-header">Improves Overall Dental Health</h3>
                <p>Once the infected pulp is removed and the tooth is filled and capped your oral health will go back to normal and the rest of your body can too.</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="columns">
                <i className="fa fa-diamond" aria-hidden="true"></i>
                <h3 className ="section-1-header">Smile More & Live Longer</h3>
                <p>Researchers have found that you can live a longer healthier life just by smiling more often. When your smile is beautiful, you're going to show it off.</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="columns">
                <i className="fa fa-bookmark-o" aria-hidden="true"></i>
                <h3 className ="section-1-header">Visually Appealing</h3>
                <p>A root canal require a crown, but it is still your natural tooth. You won't have to worry about a removable implant or an unsightly extraction.</p>
              </div>
            </div>
          </Container>
          <Container>
            <div className="col-lg-6">
              <Img fluid={service_rootcanal_1} alt="Girl Undergoing a Root Canal Treatment" />
            </div>
            <div className="col-lg-6">
              <Img fluid={service_rootcanal_2} alt="A Pretty Woman Having Her Teeth Checked" />
            </div>
          </Container>
        </section>
        <BackgroundImage
          Tag="section"
          className="pdsection2-bg"
          fluid={pd_cta_background}
          backgroundColor={`#e9f4f8`}
          alt="The Dentists at Gateway Crossing"
        >
          <div className ="col-lg-12" id ="color-overlay">
          </div>
          <section id="pdsection-2" className="page-section">
            <Container>
              <div className="col-lg-6">
                <p className="hero-text">
                  LOVE YOUR SMILE!
                </p>
                <h3 className="text-light">
                Don't miss your chance to live life with the smile you've always wanted!
                </h3>
                <p className="hero-text text-light">
                Stop delaying your life. You’re missing out on so many opportunities by being embarrassed about your teeth! Call TODAY!
                </p>
                <BtnOutlined url="tel:3176439434" class="btn-rounded" label="(317) 643-9434" />
              </div>
            </Container>
            </section>
          </BackgroundImage>
          <section id ="pd-section-3" className="page-section">
          <Container>
            <div className="col-lg-12 text-center">
              <p className ="section-1-text">ROOT CANAL DETAILS</p>
              <h2 className ="section-3-header">What is A Root Canal ?</h2>
             <p>A root canal is a procedure designed to save your natural tooth from needing to be extracted. Dr. Vogt will remove the decayed part of your tooth and the infected pulp in order to reach the abscess beneath the root and the inflamed ligament. </p>
             <p>After fulling cleaning the inside of the tooth a rubber-like material will be placed where the pulp once was and a cap will placed on top. This will look and feel just like your natural tooth. You can bite with normal pressure  and your chewing will not be affected.</p>
            </div>
          </Container>
          <Container>
            <div className="col-lg-12 text-left">
              <div className="columns">
                <h3 className ="section-3-header">Is Root Canal Therapy Dentistry Right For You?</h3>
                <p>You may have let a cavity go untreated and now you’re looking at a root canal.
You have nothing to worry about if your dentist has prescribed that you have the procedure. Millions of teeth a year are saved this way. Root canals will relieve the pain in your tooth and you can go back to eating, drinking, and smiling like normal. </p>
                <p>If you’re on the fence about having your tooth fixed, we understand. Dentistry, root canals especially, can make a lot of people nervous. You’re not alone!</p>
                <p>Before the procedure starts you are given an IV and within a few seconds you are in a state that is very much like regular sleep. You won’t remember or feel any part of your visit afterwards. </p>
                <p>Here are some of the symptoms of someone who needs a root canal:</p>
                  <ul>
                    <li>You’re experiencing sever pain while chewing or putting pressure on your tooth</li>
                    <li>Prolonged sensitivity to hot or cold after your tooth is no longer in contact</li>
                    <li>The tooth is discolored or dark</li>
                    <li>Swollen and tender gums in the surrounding area</li>
                  </ul>
              </div>
            </div>
            <div className="col-lg-12 text-left">
              <div className="columns">
                <h3 className ="section-3-header">Are Root Canal Dental Procedures Covered By My Insurance?</h3>
                <p>Every patient’s situation is different. We would love to discuss payment options with you. Please call our office at <TelLinkSimple />.</p>
              </div>
            </div>
          </Container>
        </section>
    </Layout>
  )
}

export default RootCanalTherapyPage

export const query = graphql`
  query RootCanalTherapyPageQuery {
    hero: file(name: { eq: "hero-image" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    pd_cta_background: file(name: { eq: "placeholder" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    hero_background: file(name: { eq: "about-us-hero" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    service_rootcanal_1: file(name: { eq: "rootcanal_1" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    service_rootcanal_2: file(name: { eq: "rootcanal_2" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
        twitter
        
      }
    }
  }
`
